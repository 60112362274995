import { template as template_358fbf50b77a4f1293b1d0d8e2c07008 } from "@ember/template-compiler";
const SidebarSectionMessage = template_358fbf50b77a4f1293b1d0d8e2c07008(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
