import { template as template_ad825fb8e9c34754825d5cbd1e31b540 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ad825fb8e9c34754825d5cbd1e31b540(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
